@tailwind base;
@tailwind components;
@tailwind utilities;

/*TODO create a dark mode*/

body {
    @apply text-black
    bg-slate-600
    p-6
}

nav {
    @apply
    flex flex-wrap justify-center gap-4
    p-4
    text-lg
    bg-white
    font-medium
    shadow-md
    ml-auto
    mr-auto
}

nav {
    max-width: 1048px;
}

nav.a {
    @apply
    mr-2
    ml-2
}

div.container {
    @apply
    max-w-full
}

div.pairs {
    @apply
    flex
    flex-row
    flex-wrap
    justify-center
}

div.right {
    max-width: 800px;
    width: 100%;
}

.apexcharts-canvas {
    width: 100% !important;
    max-width: 380px !important;
}

div.graphCard {
    @apply
    mt-10
    shadow-md
    bg-white
    p-4
}

div.graphCard {
    max-width: 100%;
}

div.inputSet {
    @apply
    w-52
    mr-10
}

div.inputSelectorsCard{
   @apply
    p-6
    mt-10
    shadow-md
    bg-white
}

div.inputSelectorsCard.last{
    @apply
    mb-20
}

button {
    @apply
    w-28
    mb-2
    bg-blue-50
    outline outline-1
    rounded
    p-1
}

.inputSelector, .inputSelectorShortSidebySide, .inputSelectorShort {
    @apply
    w-36
    block
    mb-2
    bg-blue-50
    outline outline-1
    rounded
}

.inputSelectorShortSidebySide{
    @apply
    w-10
    inline
    mr-4
}

.inputSelectorShort{
    @apply
    w-10
    block
    mr-4
}

.inputTextField, .inputTextFieldShort, .inputTextFieldLong {
    @apply
    block
    mb-2
    w-20
    bg-blue-50
    outline outline-1
    rounded
    p-1
}

.inputTextFieldLong {
    @apply
    w-36
}

.inputTextFieldShort {
    @apply
    w-10
    p-1
}

.inputSetTitle {
    @apply
    font-medium
}

.inputTitle {
    @apply
    text-sm
    block
    mb-0.5
}

.inputTitleCheckbox {
    @apply
    text-sm
    inline-block
    mb-0.5
}

.inputCheckbox {
    @apply
    ml-2
}

div.left {
}

div.right {
}

input, select {
    padding: 2px;
}

hr.jobIncomeSeparator{
    @apply
    border-t-2
    border-gray-300
    mt-4
    mb-4
}

.graphTitle{
    @apply
    flex justify-center
    mt-2
}

.homeContainer{
    @apply
    ml-20
    mr-20
    mt-10
    shadow-md
    bg-white
    p-2
    ml-auto
    mr-auto
    px-20
    py-12
}

.homeContainer{
    max-width: 1048px;
}

.homeContainer{
    min-height: 200px;
}

/*span.homeContent{
}*/

h1{
    @apply
    flex justify-center
    font-semibold
    font-serif
    text-xl
    drop-shadow-md
    mb-2
}


h2{
    @apply
    flex justify-center
    font-semibold
    font-serif
    text-lg
    drop-shadow-md
    mb-2
    mt-8
}

.homeContainer span{
    @apply
    block
    mt-4
    flex justify-center
}

.errorMessage{
    position: absolute;
    max-width: 400px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 40%;
    z-index: 999;
    background-color: #fff;
}

.errorMessage{
    @apply
    shadow-2xl
    rounded-xl
    p-4
}

.evalDescription{
}

.evalDescription{
    @apply
    text-center
    block
}

.getSampleValues{
    @apply
    mr-4
}

.buttonsSideBySide{
    @apply
    mt-4
    flex
    justify-center
}

.enlargedGraph{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
}